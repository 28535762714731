import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Oops!</h1>
    <h3>That page doesn't exist.</h3>
  </Layout>
)

export default NotFoundPage
